import React from 'react';
import { Helmet } from 'react-helmet';
import MainLayout from '../components/MainLayout/main-layout';
import config from '../../data/SiteConfig';
import Privacy from '../components/Privacy/privacy';

export default function PrivacyPage() {

  return(
    <MainLayout>
      <Helmet title={`Privacy | ${config.siteTitle}`} />
      <Privacy />
    </MainLayout>
  );
}
